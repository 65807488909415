import React, { useContext } from 'react'
import { Box } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import Divider from '@solid-ui-components/Divider'
import { ModalContext } from '@solid-ui-components/Modal'
import { TabsContext } from '@solid-ui-components/Tabs'
import { buildLinkProps } from '@solid-ui-components/ContentButtons'

const styles = {
  horizontal: {
    '.button-group-link': {
      py: 3
    },
    '> * + a, > * + div, > * + button': {
      ml: [3, null, null, 4],
      mt: [4, 0]
    }
  },
  vertical: {
    display: `flex`,
    flexDirection: `column`,
    a: {
      mb: [3, 2],
      px: [2, 0]
    }
  }
}

const ContentButtons = ({
  content,
  space,
  variant,
  wrapperStyles,
  onClick
}) => {
  const { setActiveModal } = useContext(ModalContext)
  const { setActiveTab } = useContext(TabsContext)

  return content ? (
    <>
      <Divider space={space} />
      <Box sx={{ ...styles[variant], ...wrapperStyles }}>
        {content?.map(
          ({ type, text, link, target, variant, width, bg, icon }, index) => {
            const { Component, linkProps } = buildLinkProps({
              content: { type, link, target, variant },
              setActiveModal,
              setActiveTab
            })

            return (
              <Component
                key={`item-${index}`}
                variant={variant || 'primary'}
                onClick={onClick}
                sx={{ width, '::after': { bg, borderColor: bg } }}
                {...linkProps}
              >
                <Icon content={icon} size='xs' /> {text}
              </Component>
            )
          }
        )}
      </Box>
    </>
  ) : null
}

export default ContentButtons

ContentButtons.defaultProps = {
  variant: 'horizontal'
}
