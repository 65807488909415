import React, { useState, useEffect } from "react";
import { Link as GLink } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import Sticky from "react-sticky-el";
import { Container, Box, Flex, css, Select } from "theme-ui";
import Reveal from "@solid-ui-components/Reveal";
import Drawer from "@solid-ui-components/Drawer";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import getImage from "@solid-ui-components/utils/getImage";

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 10,
    ".nav-container": {
      bg: `headerBg`,
      position: `fixed`,
      transition: `all 250ms ease-in`,
      overflow: `hidden`,
      py: 4,
    },
    ".nav-sticky .nav-container": {
      bg: `headerActiveBg`,
      boxShadow: `0 0 25px rgba(140,152,164,.25)`,
      py: [3, 3, 2],
    },
    //Make buttons in header smaller
    ".button-group-button": {
      minWidth: 120,
      fontSize: 1,
      px: 3,
      py: 1,
    },
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`,
    // height: [`6rem`, `7rem`], //prevent layout shift
  },
  logoContainer: {
    flexShrink: 0,
    mr: [null, null, 3, 5],
  },
  desktopMenu: {
    display: [`none`, null, `block`],
    minWidth: `auto`,
    flexGrow: 1,
  },
  mobileMenu: {
    display: [`block`, null, `none`],
  },
  logo: {
    maxWidth: "300px",
  },
  langs: {
    width: "95px",
    border: "none",
  },
  langsContainer: {
    marginLeft: "18px",
    fontSize: "16px",
    position: "relative",
    top: "-10px",
  },
};

const langSelect = {
  en: { name: "English" },
  de: { name: "German" },
  ja: { name: "Japanese" },
  zh: { name: "Chinese" },
  ru: { name: "Russian" },
  es: { name: "Spanish" },
  ko: { name: "Korean" },
};

const HeaderBlock01 = ({
  content: { images, collection },
  menuJustify,
  languages,
  switchLanguage,
}) => {
  //Close mobile menu after clicking menu items
  const [location, setLocation] = useState();
  const handleClick = (e) => setLocation(e.target.href);

  const [arr, setArr] = useState([]);
  let preSelectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    preSelectedLanguage = localStorage.getItem("selectedLanguage");
  }
  
  useEffect(() => {
    // remove the selected item from languages array
    const clsLang = languages.map((e) => {
      if (e !== preSelectedLanguage) {
        return e;
      }
    });

    // remove the undefined item from array
    const restLang = clsLang.filter(function (element) {
      return element !== undefined;
    });

    // put the selected item on the start of array
    const data = [preSelectedLanguage, ...restLang];

    setArr(data);
  }, []);

  return (
    <>
      <Sticky
        enabled="true"
        stickyClassName="nav-sticky"
        css={css(styles.wrapper)}
      >
        <Container variant="full" className="nav-container">
          <Container px="4">
            <Flex sx={styles.header}>
              <Box sx={styles.logoContainer}>
                <GLink to="/">
                  <Img
                    image={getImage(images?.[0]?.src)}
                    alt={images?.[0]?.alt}
                    css={css(styles.logo)}
                  />
                </GLink>
              </Box>
              {collection && (
                <>
                  <Box sx={styles.desktopMenu}>
                    <Reveal effect="fadeInDown">
                      <Flex
                        sx={{
                          alignItems: `center`,
                          justifyContent: menuJustify,
                        }}
                        m="-3"
                      >
                        {collection.map(
                          ({ buttons }, index) =>
                            buttons && (
                              <Box key={`item-${index}`} m="3">
                                <ContentButtons content={buttons} space={0} />
                              </Box>
                            )
                        )}
                        <Select
                          onChange={(e) => {
                            switchLanguage(e.target.value);
                          }}
                          css={css(styles.langs)}
                        >
                          {arr.map((lang) => {
                            return (
                              <option key={lang} value={lang}>
                                {langSelect[lang].name}
                              </option>
                            );
                          })}
                        </Select>
                      </Flex>
                    </Reveal>
                  </Box>
                  <Box sx={styles.mobileMenu}>
                    <Drawer
                      locationState={location}
                      buttonStyle={{ svg: { size: 32 } }}
                    >
                      {collection.map(
                        ({ buttons }, index) =>
                          buttons && (
                            <Box
                              key={`item-${index}`}
                              sx={{ fontSize: 3 }}
                              m="3"
                            >
                              <ContentButtons
                                content={buttons}
                                space={0}
                                variant="vertical"
                                onClick={handleClick}
                              />
                            </Box>
                          )
                      )}
                      <div css={css(styles.langsContainer)}>
                        <Select
                          onChange={(e) => {
                            switchLanguage(e.target.value);
                          }}
                          css={css(styles.langs)}
                        >
                          {arr.map((lang) => {
                            return (
                              <option key={lang} value={lang}>
                                {langSelect[lang].name}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                    </Drawer>
                  </Box>
                </>
              )}
            </Flex>
          </Container>
        </Container>
      </Sticky>
    </>
  );
};

HeaderBlock01.defaultProps = {
  menuJustify: `flex-end`,
};

export default WithDefaultContent(HeaderBlock01);
