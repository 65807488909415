import React from 'react'
import { Box, Input, Select } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'

const FormInput = ({ placeholder, icon, ...props }) => {
    const options = props.value.split(',');
    console.log('select', props, options)
    return (
        <Box variant='forms.field' sx={{width:'100%'}}>
            
            <Select style={{minWidth:'350px', paddingLeft:'16px', border: 'none', fontSize:'15.5px'}} >
                {options?.map((o) => (<option>{o}</option>))}
            </Select>
                
        </Box>
    )
}
export default FormInput

