import React from 'react'
import { Text, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Divider from '@solid-ui-components/Divider'

const StackTitle = ({
  align,
  kicker,
  kickerStyles,
  title,
  titleStyles,
  titleSpecialColor,
  titleSpecialStyles,
  lines,
  linesStyles,
  space
}) => {
  const linesWithText = lines?.filter(Boolean) || []

  if (!title?.text && !kicker?.text && !linesWithText.length) {
    return null
  }

  let titleWithSpecial

  // Replace special parts in title
  if (title?.text) {
    titleWithSpecial = title.text.split(/\*{2}(.*?)\*{2}/gi)
    for (var i = 1; i < titleWithSpecial.length; i += 2) {
      titleWithSpecial[i] = (
        <Text
          key={`item-${i}`}
          as='span'
          color={titleSpecialColor}
          sx={titleSpecialStyles}
        >
          {titleWithSpecial[i]}
        </Text>
      )
    }
  }

  return (
    <>
      <Box sx={{ textAlign: align }}>
        {kicker && (
          <ContentText
            content={kicker}
            variant='h5'
            sx={{ fontWeight: `body`, ...kickerStyles }}
          />
        )}
        {titleWithSpecial && (
          <ContentText
            content={title}
            variant='h2'
            sx={{
              maxWidth: align !== 'center' ? 450 : 'auto',
              display: `inline-block`,
              mb: 0,
              ...titleStyles
            }}
          >
            {titleWithSpecial}
          </ContentText>
        )}
        {linesWithText && (
          <Box mt={space || 4}>
            {linesWithText.map((line, index) => (
              <ContentText
                content={line}
                key={`item-${index}`}
                variant='medium'
                mb='0'
                sx={linesStyles}
              />
            ))}
          </Box>
        )}
      </Box>
      <Divider space={title?.space || space} />
    </>
  )
}

export default StackTitle

StackTitle.defaultProps = {
  titleSpecialColor: 'alpha',
  align: 'center'
}
